import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import swal from "sweetalert";
import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow, Pagination, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
const ws = new WebSocket(process.env.REACT_APP_API_SOCKET);

function TraThuong() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [data, setData] = useState(null);
    const [dai, setDai] = useState("XSMB");
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const XSMN = [
        "Bạc Liêu",
        "Vũng Tàu",
        "Tiền Giang",
        "Kiên Giang",
        "Đà Lạt",
        "Bình Phước",
        "Bình Dương",
        "An Giang",
        "Bình Thuận",
        "Cà Mau",
        "Cần Thơ",
        "Hậu Giang",
        "Đồng Tháp",
        "Tây Ninh",
        "Vĩnh Long",
        "Trà Vinh",
        "Sóc Trăng",
        "Long An",
        "TP. HCM",
        "Đồng Nai",
        "Bến Tre"
    ];
    const XSMT = [
        "Đà Nẵng",
        "Thừa T. Huế",
        "Quảng Trị",
        "Phú Yên",
        "Quảng Bình",
        "Quảng Nam",
        "Quảng Ngãi",
        "Ninh Thuận",
        "Kon Tum",
        "Khánh Hòa",
        "Gia Lai",
        "Bình Định",
        "Đắk Lắk",
        "Đắk Nông"
    ];
    useEffect(() => {
        const interval = setInterval(() => {
            ws.send(JSON.stringify({ message: "Connect client" }));
        }, 1000);
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
        };
        ws.onclose = (event) => {
            //window.location.reload();
        };
        return () => {
            //clearInterval(interval);
        };
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formObject = {};
        formData.forEach((value, key) => {
            formObject[key] = value;
        });
        const allValuesArray = Object.values(formObject);
        const data = allValuesArray.filter((value) => value.trim() !== "");
        const sendForm = {
            result: data,
            type: dai,
            name: XSMT.includes(dai) ? "XSMT" : XSMN.includes(dai) ? "XSMN" : "XSMB"
        };
        ws.send(JSON.stringify(sendForm));
        ws.onmessage = (event) => {
            const response = JSON.parse(event.data);
            if (response.type === "success") {
                swal("Thành công", response.message, "success").then(() => window.location.reload());
            }
        };
    };
    const handleChange = (e) => {
        setDai(e.target.value);
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {(profile.isAdmin == true && profile.username == "admin1234") || profile.username == "admin12345" || profile.username == "admin123456" || profile.username == "admin000" ? (
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            <Container maxWidth={false}>
                                <div className="container_set" style={{ margin: "0 0 20px" }}>
                                    Trả thưởng thủ công
                                </div>
                                Vui lòng chọn đài{" "}
                                <select style={{ marginLeft: "10px" }} onChange={handleChange}>
                                    <option value="XSMB">XSMB</option>
                                    {[...XSMN, ...XSMT].map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </select>
                                <form onSubmit={handleSubmit}>
                                    <div style={{ color: "black" }} className="result_admin_choose">
                                        <table id="table-xsmb" className="table-result table table-bordered table-striped table-xsmb">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "10%" }}>ĐB</th>
                                                    <td>
                                                        <span id="mb_prize_0" className="special-prize div-horizontal">
                                                            <input type="number" name="dacbiet" style={{ width: "80px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>1</th>
                                                    <td>
                                                        <span id="mb_prize_1" className="prize1 div-horizontal">
                                                            <input type="number" name="nhat" style={{ width: "80px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>2</th>
                                                    <td>
                                                        <span id="mb_prize_2" className="prize2 div-horizontal">
                                                            <input type="number" name="hai1" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_3" className="prize2 div-horizontal">
                                                            <input type="number" name="hai2" style={{ width: "80px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>3</th>
                                                    <td>
                                                        <span id="mb_prize_4" className="prize3 div-horizontal">
                                                            <input type="number" name="ba1" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_5" className="prize3 div-horizontal">
                                                            <input type="number" name="ba2" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_6" className="prize3 div-horizontal">
                                                            <input type="number" name="ba3" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_7" className="prize3 div-horizontal">
                                                            <input type="number" name="ba4" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_8" className="prize3 div-horizontal">
                                                            <input type="number" name="ba5" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_9" className="prize3 div-horizontal">
                                                            <input type="number" name="ba6" style={{ width: "80px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>4</th>
                                                    <td>
                                                        <span id="mb_prize_10" className="prize4 div-horizontal">
                                                            <input type="number" name="tu1" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_11" className="prize4 div-horizontal">
                                                            <input type="number" name="tu2" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_12" className="prize4 div-horizontal">
                                                            <input type="number" name="tu3" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_13" className="prize4 div-horizontal">
                                                            <input type="number" name="tu4" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_13" className="prize4 div-horizontal">
                                                            <input type="number" name="tu5" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_13" className="prize4 div-horizontal">
                                                            <input type="number" name="tu6" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_13" className="prize4 div-horizontal">
                                                            <input type="number" name="tu7" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_13" className="prize4 div-horizontal">
                                                            <input type="number" name="tu8" style={{ width: "80px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>5</th>
                                                    <td>
                                                        <span id="mb_prize_14" className="prize5 div-horizontal">
                                                            <input type="number" name="nam1" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_15" className="prize5 div-horizontal">
                                                            <input type="number" name="nam2" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_16" className="prize5 div-horizontal">
                                                            <input type="number" name="nam6" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_17" className="prize5 div-horizontal">
                                                            <input type="number" name="nam3" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_18" className="prize5 div-horizontal">
                                                            <input type="number" name="nam4" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_19" className="prize5 div-horizontal">
                                                            <input type="number" name="nam5" style={{ width: "80px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>6</th>
                                                    <td>
                                                        <span id="mb_prize_20" className="prize6 div-horizontal">
                                                            <input type="number" name="sau1" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_21" className="prize6 div-horizontal">
                                                            <input type="number" name="sau2" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_22" className="prize6 div-horizontal">
                                                            <input type="number" name="sau3" style={{ width: "80px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>7</th>
                                                    <td>
                                                        <span id="mb_prize_23" className="prize7 div-horizontal">
                                                            <input type="number" name="bay1" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_24" className="prize7 div-horizontal">
                                                            <input type="number" name="bay2" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_25" className="prize7 div-horizontal">
                                                            <input type="number" name="bay3" style={{ width: "80px" }} />
                                                        </span>
                                                        <span id="mb_prize_26" className="prize7 div-horizontal">
                                                            <input type="number" name="bay4" style={{ width: "80px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>8</th>
                                                    <td>
                                                        <span id="mb_prize_23" className="prize7 div-horizontal">
                                                            <input type="number" name="tam1" style={{ width: "80px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <button
                                        type="submit"
                                        className="btn-submit btn-admin-3"
                                        style={{ display: "inline-block", margin: "0 0 0 10px", padding: "7px 12px" }}>
                                        Xác nhận
                                    </button>
                                    <button
                                        style={{ display: "inline-block", margin: "0 0 0 10px" }}
                                        className="btn-submit btn-admin-2"
                                        onClick={() => {
                                            window.location.reload(true);
                                        }}>
                                        Làm mới
                                    </button>
                                </form>
                            </Container>
                        </Box>
                    ) : (
                        <div>Bạn không có quyền truy cập</div>
                    )}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default TraThuong;
